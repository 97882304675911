import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { delay } from 'rxjs/operators'

import { Session } from 'system-frontend'
import { AuthManager } from 'system-license'

@Injectable({
  providedIn: 'root'
})
export class MockAuthManager implements AuthManager {
    setSession(session: Session) {
    }

    numberInfoById(id: number, action: any): Observable<any> {
     return of({
             number: 1,
             identifiers: [
                {
                  items: [
                    {
                      key: "CPU",
                      value: "8086"
                    },
                    {
                      key: "Disco",
                      value: "WD Caviar"
                    }
                  ]
                },
                {
                  items: [
                    {
                      key: "CPU",
                      value: "ARM"
                    },
                    {
                      key: "Disco",
                      value: "5 1/4"
                    }
                  ]
                }
             ],
             posname: "pos01",
             companyName: "Maicorsof",
             branchName: "Amstrong"
         }).pipe(delay(500))
    }

    numberInfoByKey(key: any  , action: any): Observable<number> {
     return of(42).pipe(delay(500))
    }

    authorize(requestKey: string,
        license: any,
        invoiceLineIds: any,
        action: any): Observable<string> {
      return of("123").pipe(delay(500))
    }

    reject(requestKey: string, action: any): Observable<void> {
      return of(null).pipe(delay(500))
    }

    getLi(serialization: string): any {
     return {
             identifiers: [
                {
                  items: [
                    {
                      key: "CPU",
                      value: "8086"
                    },
                    {
                      key: "Disco",
                      value: "WD Caviar"
                    }
                  ]
                },
                {
                  items: [
                    {
                      key: "CPU",
                      value: "ARM"
                    },
                    {
                      key: "Disco",
                      value: "5 1/4"
                    }
                  ]
                }
             ],
             modules: ["SERVER", "PURCHASE"],
             observations: "Una licencia",
             types: ["POS", "SERVER"],
             business: "RESTAURANT",
             product: "producto",
             appname: "starpos",
             posname: "pos01",
             companyId: "123",
             companyDoc: "456",
             fiscalPrinter: "hasar",
             serial: "123",
             expirationDate: "1/1/2001",
             version: "1",
             startDate: "1/1/2001",
             companyName: "Maicorsof",
             branchName: "Amstrong",
             invoiceEmitter: 666,
             invoiceNumber: 777
         }
    }

    getKey(li: any): string {
      return "key123"
    }
}
