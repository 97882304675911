// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/app/home/home.page.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAEA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,QAAA;EACA,2BAAA;AAAF;;AAGA;EACE,eAAA;EACA,iBAAA;AAAF;;AAGA;EACE,eAAA;EACA,iBAAA;EAEA,cAAA;EAEA,SAAA;AAFF;;AAKA;EACE,qBAAA;AAFF","sourcesContent":["#container {\r\n  text-align: center;\r\n\r\n  position: absolute;\r\n  left: 0;\r\n  right: 0;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n}\r\n\r\n#container strong {\r\n  font-size: 20px;\r\n  line-height: 26px;\r\n}\r\n\r\n#container p {\r\n  font-size: 16px;\r\n  line-height: 22px;\r\n\r\n  color: #8c8c8c;\r\n\r\n  margin: 0;\r\n}\r\n\r\n#container a {\r\n  text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
