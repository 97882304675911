import { Component } from '@angular/core'
import { Session } from 'system-frontend'
import { Router } from '@angular/router'

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  constructor(private session: Session,
              private router: Router) { }

  ionViewWillEnter() {
    if (!this.session.isLogged()) {
      this.router.navigate(['/login'])
    }
  }
}

