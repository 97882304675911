import { Component, ElementRef, ViewChild } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { MessageLog, Session, ERROR_BY_CODE } from 'system-frontend'
import { ALL_COMPANIES, ALL_ROLES, ALL_USERS, CHANGE_PASSWORD } from 'system-security'
import { AuthManager, PENDING, SEARCH, SEARCH_SALES_CUSTOMER, LICENSE_TOTALS_BY_CUSTOMER, MANUAL_REQUEST, ALL_INVOICES, ALL_PRODUCTS, ALL_SALES_CUSTOMERS} from 'system-license'
import { MockAuthManager } from './mock.auth.manager'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  logged: boolean
  canPendingLicenses: boolean
  canSearchLicenses: boolean
  canSearchSalesCustomers: boolean
  canRoleList: boolean
  canCompanyList: boolean
  canUserList: boolean
  canChangePassword: boolean
  canLicenseTotalsByCustomer: boolean
  canManualLicenseRequest: boolean
  messageColor: string
  canInvoiceList: boolean
  canProductList: boolean
  canSalesCustomerList: boolean
  canErrorByCode: boolean

  constructor(session: Session,
              router: Router,
              mockAuthManager: MockAuthManager,
              private messageLog: MessageLog) {
    if (document.URL.indexOf("mock=true") >= 0) {
      window["mockSystemServices"] = true
      window["authManager"] = mockAuthManager
    }
    if (document.URL.indexOf("mockAuth=true") >= 0) {
      window["authManager"] = mockAuthManager
    }
    let authManager: AuthManager = window["authManager"]
    if (!!authManager) {
      authManager.setSession(session)
    }
    router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.logged = session.isLogged()
          this.canPendingLicenses = session.hasFunction(PENDING)
          this.canSearchLicenses = session.hasFunction(SEARCH)
          this.canSearchSalesCustomers = session.hasFunction(SEARCH_SALES_CUSTOMER)
          this.canRoleList = session.hasFunction(ALL_ROLES)
          this.canCompanyList = session.hasFunction(ALL_COMPANIES)
          this.canUserList = session.hasFunction(ALL_USERS)
          this.canChangePassword = session.hasFunction(CHANGE_PASSWORD)
          this.canLicenseTotalsByCustomer = session.hasFunction(LICENSE_TOTALS_BY_CUSTOMER)
          this.canManualLicenseRequest = session.hasFunction(MANUAL_REQUEST)
          this.canInvoiceList = session.hasFunction(ALL_INVOICES)
          this.canProductList = session.hasFunction(ALL_PRODUCTS)
          this.canSalesCustomerList = session.hasFunction(ALL_SALES_CUSTOMERS)
          this.canErrorByCode = session.hasFunction(ERROR_BY_CODE)
        }
      })
    messageLog.onStateChange((hasErrors: boolean, hasWarnings: boolean) => {
      if (hasErrors) {
        this.messageColor = "danger"
      } else if (hasWarnings) {
        this.messageColor = "warning"
      } else {
        this.messageColor = ""
      }
    })
  }

  showMessages() {
    this.messageLog.show()
  }
}
