import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import { HomePage } from './home/home.page'

import { ErrorByCodePage } from 'system-frontend'

import { LoginPage } from 'system-security'
import { LogoutPage } from 'system-security'
import { ChangePasswordPage } from 'system-security'
import { CompanyEditPage } from 'system-security'
import { CompanyListPage } from 'system-security'
import { RoleEditPage } from 'system-security'
import { RoleListPage } from 'system-security'
import { UserEditPage } from 'system-security'
import { UserListPage } from 'system-security'

import { AuthorizationPage } from 'system-license'
import { PendingPage } from 'system-license'
import { SearchPage } from 'system-license'
import { CustomerLicensesPage } from 'system-license'
import { ManualRequestPage } from 'system-license'
import { InvoiceEditPage } from 'system-license'
import { InvoiceListPage } from 'system-license'
import { LicenseDetailsPage } from 'system-license'
import { SalesCustomerEditPage } from 'system-license'
import { SalesCustomerListPage } from 'system-license'
import { ProductEditPage } from 'system-license'
import { ProductListPage } from 'system-license'

const routes: Routes = [
  { path: 'home', component: HomePage },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: 'login', component: LoginPage },
  { path: 'logout', component: LogoutPage },
  { path: 'changePassword', component: ChangePasswordPage },
  { path: 'licenseAuthorization', component: AuthorizationPage },
  { path: 'pendingLicenses', component: PendingPage },
  { path: 'searchLicenses', component: SearchPage },
  { path: 'customerLicenses', component: CustomerLicensesPage },
  { path: 'manualLicenseRequest', component: ManualRequestPage },
  { path: 'invoiceEdit', component: InvoiceEditPage },
  { path: 'invoiceList', component: InvoiceListPage },
  { path: 'licenseDetails', component: LicenseDetailsPage },
  { path: 'productEdit', component: ProductEditPage },
  { path: 'productList', component: ProductListPage },
  { path: 'salesCustomerEdit', component: SalesCustomerEditPage },
  { path: 'salesCustomerList', component: SalesCustomerListPage },
  { path: 'companyEdit', component: CompanyEditPage },
  { path: 'companyList', component: CompanyListPage },
  { path: 'roleEdit', component: RoleEditPage },
  { path: 'roleList', component: RoleListPage },
  { path: 'userEdit', component: UserEditPage },
  { path: 'userList', component: UserListPage },
  { path: 'errorByCode', component: ErrorByCodePage },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
